


























































import {
  computed,
  defineComponent,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';

import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';

export default defineComponent({
  name: 'EditorialPushItem',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    mainLevelName: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      app: { $gtm }
    } = useContext();
    const { isDesktop } = useScreenSize();
    const megaMenuMobileStore = useMegaMenuMobileStore();
    const router = useRouter();
    const { getBannerData, getCTALinkData } = useDeclination(props.sectionData);

    const closeMenu = () => {
      megaMenuMobileStore.hide();
      megaMenuMobileStore.isMenuLayersVisible = false;
    };

    const goToPage = () => {
      closeMenu();
      if (getBannerData.value.link) {
        router.push(getBannerData.value.link);
      }
    };

    const getClickNavDetails = (menuItemName) => {
      $gtm.push({
        event: 'clickNav',
        mainLevel: props.mainLevelName,
        subLevel: menuItemName
      });
    };

    const textAlignment = computed(() => {
      const alignment = props.sectionData?.textalign?.toLowerCase() || 'center';
      return `text-${alignment}`;
    });

    return {
      closeMenu,
      getBannerData,
      getCTALinkData,
      isDesktop,
      goToPage,
      getClickNavDetails,
      textAlignment
    };
  }
});
